// helpers
import { ls } from '../Localstorage';

// globals
import { API, httpMethods } from '../Constants';

const isDevelopmentMode = process.env.NODE_ENV !== 'production';

const request = async (endpoint, method, data) => {
	const url = `${API.url}${endpoint}`;
	const headers = new Headers({
		'X-Token': API.key,
		'Content-Type': 'application/json'
	});
	const token = ls('token');
	if (token) headers.append('Authorization', `Bearer ${token}`);
	const options = {
		method: method || httpMethods.GET,
		headers,
		body: data ? JSON.stringify(data) : null
	};
	const response = await fetch(url, options);
	if (response.status === 200) {
		if (isDevelopmentMode) console.log(response);
		try {
			const json = await response.json();
			if (isDevelopmentMode) console.log(json);
			return json;
		} catch (ex) {
			return null;
		}
	}
	console.log('error:', response);
	throw await response.json();
};

const Http = {
	request
};

export default Http;