import React, {useEffect,useState} from 'react';
import {Link} from 'react-router-dom';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {Clients} from './../../Models';

// helpers
import {ls} from '../../Globals/Localstorage';
import {empty} from '../../Globals/Utils';

// globals
import {userStatusName} from '../../Globals/Constants';

const ClientsScreen = () => {
	const [clients, setClients] = useState(null);
	const [dataFull, setDataFull] = useState(null);
	const [status, setStatus] = useState(null);
	useEffect(() => {
		const clientsGet = async () => {
			const user = ls('user');
			if (!user) {
				window.location.href = '/login';
				return;
			}
			const d = await Clients.get();
			setClients(dataSet(d));
			setDataFull(d);
		};
		clientsGet();
	}, []);
	const dataSet = (data) => dgDataPrepare(data, 'clients', ['_id','username','type','createdAt','status']);
	const dataStatus = (data, status) => data.filter(f => f.status === status);
	const handleStatus = (e) => {
		let status = e.target.value;
		let d = dataFull;
		if (empty(status)) status = null;
		if (status !== null) d = dataStatus(d, parseInt(status));
		setClients(dataSet(d));
		setStatus(status);
	}
	return (
		<Template>
			{clients === null ? <Loader /> :
				<DataGrid
					title={'Клиенты'}
					keys={['Имя пользователя','Тип','Дата регистрации','Статус']}
					link={'client'}
					data={clients}
					dataFull={dataFull}
					controlAdd={<Link to={'/client'}>Добавить</Link>}
					controlPanel={<>
						<div></div>
						<div>
							<span>Статус</span>
							<select onChange={handleStatus}>
								<option value=" ">все</option>
								{userStatusName.map((v,i) => <option key={i} value={i}>{v}</option>)}
							</select>
						</div>
					</>}
					notFound={<NotFound />} />
			}
		</Template>
	);
};

export default ClientsScreen;