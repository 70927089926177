import React, {useEffect,useState} from 'react';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import DataGrid,{dgDataPrepare} from '../../Components/DataGrid';
import NotFound from '../../Components/NotFound';

// models
import {ClientRequests} from './../../Models';

const RequestsScreen = () => {
	const [requests, setRequests] = useState(null);
	const [dataFull, setDataFull] = useState(null);
	useEffect(() => {
		const pagesGet = async () => {
			const d = await ClientRequests.get();
			d.forEach((v) => {
				v.name = `${v.firstname||''} ${v.lastname||''} ${v.middlename||''}`.replace('  ', ' ');
			});
			setRequests(dataSet(d));
			setDataFull(d);
		};
		pagesGet();
	}, []);
	const dataSet = (data) => dgDataPrepare(data, 'requests', ['_id','name','phone','inn','createdAt']);
	return (
		<Template>
			{requests === null ? <Loader /> :
				<DataGrid
					title={'Заявки на подключение'}
					keys={['Имя','Телефон','ИНН','Дата']}
					link={'request'}
					data={requests}
					dataFull={dataFull}
					notFound={<NotFound />} />
			}
		</Template>
	);
};

export default RequestsScreen;