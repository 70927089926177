import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get			= async ()			=> await Http.request('clientrequests');
const getById		= async (id)		=> await Http.request(`clientrequest/${id}`);

const update		= async (id, data)	=> await Http.request(`clientrequest/${id}`, httpMethods.PUT, data);


export {
	get,
	getById,
	update
}