import React, {useEffect,useState} from 'react';
import { Link } from 'react-router-dom';

// plug-ins
import Moment from 'moment';
import 'moment/locale/ru';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import Back from '../../Components/Back';
import NotFound from '../../Components/NotFound';
import Alert,{alertShow} from '../../Components/Alert';

// models
import {ClientRequests} from './../../Models';

// helpers
import { phoneFormatter } from '../../Globals/Utils';

// styles
import './styles.css';

const RequestScreen = (props) => {
	const [loading, setLoading] = useState(true);
	const [id] = useState(props.match.params.id||0);
	const [request, setRequest] = useState(null);
	useEffect(() => {
		Moment.locale('ru');
		(async () => {
			const request = await ClientRequests.getById(id);
			setRequest(request);
			setLoading(false);
		})();
	}, [id]);
	const save = async (e) => {
		e.preventDefault();
		try {
			await ClientRequests.update(id);
			alertShow('Данные обновлены');
			setTimeout(() => window.location.href = '/requests', 2000);
		} catch (ex) {
			console.log(ex)
			alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true);
		}
	}
	/*
	const decline = async (e) => {
		e.preventDefault();
		try {
			await ClientRequests.update(id);
			alertShow('Данные обновлены');
			setTimeout(() => window.location.href = '/requests', 2000);
		} catch (ex) {
			console.log(ex)
			alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true);
		}
		return false
	}
	*/
	return (
		<Template>
			{loading ? <Loader /> :
				<form onSubmit={save}>
					<Back title={'Заявка от пользователя'} link='requests' />
					{request === null ? <NotFound /> :
						<>
							<div className="form-container form-container-last">
								<div className="cell">
									<label>
										<span>Дата заявки</span>
										<div>{Moment(request.createdAt).format('lll')}</div>
									</label>
								</div>
								<div className="cell">
									<label>
										<span>Имя</span>
										<div>{`${request.firstname||''} ${request.lastname||''} ${request.middlename||''}`.replace('  ', ' ')}</div>
									</label>
								</div>
								<div className="cell">
									<label>
										<span>Имя пользователя</span>
										<div><Link to={`/client/${request.clientId}`}>{request.username}</Link></div>
									</label>
								</div>
								<div className="cell">
									<label>
										<span>Телефон</span>
										<div>{phoneFormatter(request.phone)}</div>
									</label>
								</div>
								<div className="cell">
									<label>
										<span>ИНН</span>
										<div>{request.inn}</div>
									</label>
								</div>
								<div className="cell">
									<label>
										<span>Комментарий</span>
										<div>{request.comment}</div>
									</label>
								</div>
								<div className="buttons">
									<button type="submit">Принять</button>
									{/* <Link to={'/'} onClick={(e) => decline(e)}>отказать</Link> */}
								</div>
							</div>
						</>
					}
				</form>
			}
			<Alert />
		</Template>
	);
};

export default RequestScreen;