import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const get		= async ()			=> await Http.request('settings');
const getById	= async (id)		=> await Http.request(`setting/${id}`);

const getByKey	= (code, settings)	=> settings.find(f => f.code === code);

const add		= async (data)		=> await Http.request('setting', httpMethods.POST, data);
const update	= async (id, data)	=> await Http.request(`setting/${id}`, httpMethods.PUT, data);


export {
	get,
	getById,
	getByKey,
	add,
	update
}