import React, {useEffect,useState} from 'react';

// plug-ins
import Moment from 'moment';
import 'moment/locale/ru';

// components
import Template from '../../Components/Template';
import Loader from '../../Components/Loader';
import Back from '../../Components/Back';
import Alert,{alertShow} from '../../Components/Alert';
import {useInput} from '../../Components/Hooks/Input';
import NotFound from '../../Components/NotFound';

// models
import {Clients} from './../../Models';

// helpers
import { phoneFormatter } from '../../Globals/Utils';

// globals
import { clientType, clientTypeName, userStatus, userStatusName } from '../../Globals/Constants';

// styles
import './styles.css';

const ClientScreen = (props) => {
	const [loading, setLoading] = useState(true);
	const [id] = useState(props.match.params.id||0);
	const {value:name, setValue:setName, bind:bindName} = useInput('');
	const {value:username, setValue:setUsername, bind:bindUsername} = useInput('');
	const {value:phone, setValue:setPhone, bind:bindPhone} = useInput('');
	const {value:description, setValue:setDescription, bind:bindDescription} = useInput('');
	const {value:link, setValue:setLink, bind:bindLink} = useInput('');
	const [type, setType] = useState(clientType.ACTIVE);
	const [status, setStatus] = useState(userStatus.ACTIVE);
	const [client, setClient] = useState(null);
	useEffect(() => {
		(async () => {
			if (id) {
				const client = await Clients.getById(id);
				setName(client.name);
				setUsername(client.username);
				setPhone(client.phone);
				setDescription(client.description);
				setLink(client.link);
				setType(client.type);
				setClient(client);
			}
			setLoading(false);
		})();
	}, [id]);
	const selectStatusHandle = (e) => setStatus(parseInt(e.target.value));
	const selectTypeHandle = (e) => setType(parseInt(e.target.value));
	const save = async (e) => {
		e.preventDefault();
		const data = {name,link,description,type,status};
		try {
			if (id === 0) {
				data.phone = phone;
				data.username = username;
				await Clients.add(data);
				alertShow('Клиент добавлен');
			} else {
				await Clients.update(id, data);
				alertShow('Данные обновлены');
			}
		} catch (ex) {
			console.log(ex)
			alertShow('<b>Ошибка!</b><br/>При сохранении данных возникла ошибка', true);
		}
	}
	return (
		<Template>
			{loading ? <Loader /> :
				<form onSubmit={save}>
					<Back title={id === 0 ? 'Добавить клиента' : 'Редактирование клиента'} link='clients'
						controlAdd={<a href="/client" className="add">Добавить</a>}  />
					{id && client === null ? <NotFound /> :
						<>
							<div className="form-container">
								<div className="cell">
									<label>
										<span>Имя</span>
										<input type="text" {...bindName} placeholder="Имя" className="double" />
									</label>
								</div>
								<div className="cell">
									<label>
										<span>Имя пользователя</span>
										<input type="text" {...bindUsername} placeholder="Имя пользователя" className="double" />
									</label>
								</div>
								<div className="cell">
									<label>
										<span>Телефон</span>
										{id === 0 ?
												<input type="text" {...bindPhone} placeholder="Телефон" />
											:
												<div>{phoneFormatter(phone)}</div>
										}
									</label>
								</div>
								<div className="cell">
									<label>
										<span>Комментарий</span>
										<textarea name="description" placeholder="Комментарий" {...bindDescription}></textarea>
									</label>
								</div>
								<div className="cell">
									<label>
										<span>Ссылка</span>
										<input type="text" {...bindLink} placeholder="Ссылка" />
									</label>
								</div>
								<div className="cell">
									<label>
										<span>Тип пользователя</span>
										<select className="select-minimal" onChange={selectTypeHandle} value={type}>
											{clientTypeName.map((v,i) => <option key={i} value={i}>{v}</option>)}
										</select>
									</label>
								</div>
								{id ? 
									<div className="cell">
										<label>
											<span>Статус</span>
											<select className="select-minimal" onChange={selectStatusHandle} value={status}>
												{userStatusName.map((v,i) => i === 0 ? null : <option key={i} value={i}>{v}</option>)}
											</select>
										</label>
									</div> : null}
								{id ?
									<div className="cell-oneline">
										<div className="cell">
											<label>
												<span>Дата регистрации</span>
												<div>{Moment(client.createdAt).format('lll')}</div>
											</label>
										</div>
										<div className="cell">
											<label>
												<span>Последние изменения</span>
												<div>{Moment(client.updatedAt).format('lll')}</div>
											</label>
										</div>
									</div> : null}
									<div className="buttons">
										<button type="submit">Сохранить</button>
									</div>
							</div>
						</>
					}
				</form>
			}
			<Alert />
		</Template>
	);
};

export default ClientScreen;