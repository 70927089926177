import React from 'react';
import {BrowserRouter,Switch,Route} from 'react-router-dom';

// screens
import Start			from './Screens/Start';
import Login			from './Screens/Login';

import Requests			from './Screens/Requests';
import Request			from './Screens/Request';

import Users			from './Screens/Users';
import User				from './Screens/User';

import Clients			from './Screens/Clients';
import Client			from './Screens/Client';

import Settings			from './Screens/Settings';
import Setting			from './Screens/Setting';

import Error			from './Screens/Error';

// start
const App = () => (
	<BrowserRouter>
		<Switch>
			<Route path='/' component={Start} exact />
			<Route path='/login' component={Login} exact />

			<Route path='/requests' component={Requests} exact />
			<Route path='/request/:id' component={Request} exact />
			
			<Route path='/clients' component={Clients} exact />
			<Route path='/client' component={Client} exact />
			<Route path='/client/:id' component={Client} exact />

			<Route path='/users' component={Users} exact />
			<Route path='/user' component={User} exact />
			<Route path='/user/:id' component={User} exact />

			<Route path='/settings' component={Settings} exact />
			<Route path='/setting' component={Setting} exact />
			<Route path='/setting/:id' component={Setting} exact />

			<Route path='/error' component={Error} status={500} exact />
			<Route path='/error401' component={Error} status={401} exact />
			<Route path='/error403' component={Error} status={403} exact />
			<Route path='/error404' component={Error} status={404} exact />
			<Route path='*' component={Error} status={404} />
		</Switch>
	</BrowserRouter>
);

export default App;