import { httpMethods } from '../../Globals/Constants';
import Http from '../../Globals/Http';


const login		= async (username, password)	=> await Http.request('user/login', httpMethods.POST, {username,password});

const get		= async ()						=> await Http.request('users');
const getById	= async (id)					=> await Http.request(`user/${id}`);

const add		= async (data)					=> await Http.request('user', httpMethods.POST, data);
const update	= async (id, data)				=> await Http.request(`user/${id}`, httpMethods.PUT, data);


export {
	login,
	get,
	getById,
	add,
	update
}